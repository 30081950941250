<template>
  <div>
    <v-card class="activate-plug-tile ma-2">
      <v-card-title>
        <h3 id="access-control-title">
          {{ show_form && !isEdit ? 'Add Manager' : show_form && isEdit ? 'Edit Manager' : 'Access Control' }}
        </h3>
        <v-spacer />
      </v-card-title>
      <v-alert :value="(error || success)" :type="(error ? 'error' : 'success')" dismissible @input="isDismissed">
        {{ error ? errorMsg : successMsg }}
      </v-alert>
      <v-card-text class="text-xs-center">
        <v-flex v-if="!!loading">
          <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
        </v-flex>
        <v-flex v-else>
          <div v-if="!show_form && !loading" class="d-flex align-center">
            <h4 class="text-xs-left py-2 mr-2">My Account</h4>
            <v-icon
              class="text-xs-right tooltip-icon d-inline-block"
              v-tooltip="{
                content: 'Grant other managers, view and/or edit access to your account',
                placement: 'pull-right',
                trigger: 'click hover',
              }"
            >
              info
            </v-icon>
          </div>
          <MultiManagementTable
            v-if="!loading && !show_form"
            :show-form="show_form"
            @show-form="show_form = true"
            @edit="editManager"
          />
          <MultiManagementForm 
            v-if="show_form && !loading" 
            class="mt-0" 
            :loading="loading"
            :edit="isEdit" 
            :error="error"
            :email="email" 
            :selectedAccess="selectedAccess" 
            @input:email="email = $event"
            @input:access="selectedAccess = $event"
            @cancel="resetValue" 
            @confirm="selectedAccess === 'revoke' ? removeProxy() : setProxy()" 
          />
          <div v-if="!show_form && !loading" class="d-flex align-center my-3">
            <h4 class="text-xs-left mr-2">Other Accounts</h4>
            <v-icon
              class="text-xs-right tooltip-icon d-inline-block"
              v-tooltip="{
                content: 'List of manager who have granted you access to their account',
                placement: 'pull-right',
                trigger: 'click hover',
              }"
            >
              info
            </v-icon>
          </div>

          <MultiManagementTable
            v-if="!loading && !show_form"
            :show-form="show_form"
            type="other"
            empty-data-text="No Additional Accounts"
          />
        </v-flex>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import validators from "@/library/helpers/validators";
import { mapState, mapGetters } from "vuex";
import Api from "@/library/apis/Api";
import { OwnerProxyOptions } from "@/constants"
import { ApiHelpers } from "@/library/helpers";
import MultiManagementForm from "@/components/multiManagement/MultiManagementForm.vue";
import MultiManagementTable from "@/components/multiManagement/MultiManagementTable.vue";

export default {
  name: "MultiManagement",
  components: {
    MultiManagementForm,
    MultiManagementTable
  },
  data() {
    return {
      email: "", 
      success: false,
      selectedAccess: "", 
      show_form: false,
      error: false,
      errorMsg: ``,
      successMsg: ``,
      isEdit: false,
    };
  },
  computed: { 
    ...mapState({
      role: (state) => state.Auth.role,
      adminActiveView: (state) => state.Admin.activeView,
      apiCalls: state => state.Global.apiCalls || [],
    }),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner",
    }),
    validators: () => validators,
    OwnerProxyOptions: () => OwnerProxyOptions,
    loading() {
      return this.apiCalls.some(({ url }) => url.includes("owner-proxy/") )
    }
  },
  watch: { 
    "email"(v, oldV) {
      this.$nextTick(() => {
        if (v && oldV != v) this.email = this.email.toLowerCase().trim();
      });
    },
    "show_form"(v) {
      if (v) {
        this.error = false;
        this.success = false;
      }
    }
  },
  methods: {
    editManager(selected = {}) {
      this.isEdit = true;
      this.show_form = true;
      this.email = selected.proxy_username
    },
    resetValue() { 
      this.isEdit = false; 
      this.show_form = false;
      this.email = ""; 
      this.selectedAccess = "";
    },
    isDismissed() { 
      this.error = false; 
      this.errorMsg = ""; 
      this.success = false; 
      this.successMsg = ""; 
    },
    setProxy() {
      this.$store.dispatch("loading", true);
      this.success = false;
      this.error = false;
      const proxyPayload = {
        ...ApiHelpers.getUsernamePayloadOfViewAs(this.adminActiveView, this.viewAsOwner),
        ownerProxyUsername: this.email,
        rightsList: OwnerProxyOptions[this.selectedAccess],
      };
      Api.proxySet(this.role, proxyPayload)
        .then(() => {
          this.$store.dispatch("loading", false);
          this.$nextTick(() => {
            this.show_form = false;
            this.success = true;
            this.$store.dispatch("OwnerProxy/fetchProxies");
            this.$store.dispatch("OwnerProxy/fetchCanActAsUsers");
            this.successMsg = this.isEdit ? "Manager edited successfully." : "Manager added successfully.";
            this.$store.dispatch("snackbar", {
              message: this.successMsg,
            });
            this.resetValue(); 
          });
        })
        .catch(error => {
          this.$store.dispatch("loading", false);
          this.error = true; 
          this.errorMsg = error.response.status === 400
            ? (this.isEdit ? "Failed to edit manager." : "Failed to add manager. Please ensure that the manager proxy exists.")
            : "An error occurred while processing your request. Please try again later.";
          this.$store.dispatch("snackbar", {
            message: this.errorMsg,
          });
        })
    },
    removeProxy() {
      if (!this.isEdit) return
      this.$store.dispatch("loading", true);
      this.error = false; 
      this.success = false;

      Api.proxyRemove(this.role, {  ...ApiHelpers.getUsernamePayloadOfViewAs(this.adminActiveView, this.viewAsOwner), ownerProxyUsername: this.email })
        .then(() => {
          this.$store.dispatch("loading", false);
          this.success = true;
          this.$nextTick(() => {
            this.show_form = false;
            this.$store.dispatch("OwnerProxy/fetchProxies");
            this.$store.dispatch("OwnerProxy/fetchCanActAsUsers");
            this.$store.dispatch("snackbar", {
              message: "Manager Proxy has been successfully removed.",
            });
            this.successMsg = "Manager Proxy has been successfully removed.";
          });
          this.resetValue();
        })
        .catch(() => {
          this.error = true; 
          this.errorMsg = "Failed to remove manager proxy. Please try again later.";
          this.$store.dispatch("snackbar", {
            message: "An error occurred while removing the manager proxy. Please try again later.",
          });
        })
    }
  },
  mounted() {
    this.$store.dispatch("OwnerProxy/fetchProxies")
    this.$store.dispatch("OwnerProxy/fetchCanActAsUsers")
  }
}
</script>

<style lang="scss" scoped>
.multimanagement {
  &__table {
    td.longtext {
      word-break: break-word;
    }
    th {
      background-color: #f5f5f5; 
      border-top: 1px solid #ddd; 
      border-bottom: 1px solid #ddd; 
      border-left: 1px solid #ddd; 
      border-right: 1px solid #ddd; 
      padding: 5px;
    }

    td {
      padding: 5px;
      border-top: 1px solid #ddd; 
      border-bottom: 1px solid #ddd; 
      border-left: 1px solid #ddd; 
      border-right: 1px solid #ddd; 
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      width: 2% !important; 
    }
    
    tr:hover {
      background-color: #f0f0f0; 
    }
  }
}
.table-container {
  overflow-x: auto;
}
</style>

