var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"activate-plug-tile ma-2"},[_c('v-card-title',[_c('h3',{attrs:{"id":"access-control-title"}},[_vm._v("\n        "+_vm._s(_vm.show_form && !_vm.isEdit ? 'Add Manager' : _vm.show_form && _vm.isEdit ? 'Edit Manager' : 'Access Control')+"\n      ")]),_c('v-spacer')],1),_c('v-alert',{attrs:{"value":(_vm.error || _vm.success),"type":(_vm.error ? 'error' : 'success'),"dismissible":""},on:{"input":_vm.isDismissed}},[_vm._v("\n      "+_vm._s(_vm.error ? _vm.errorMsg : _vm.successMsg)+"\n    ")]),_c('v-card-text',{staticClass:"text-xs-center"},[(!!_vm.loading)?_c('v-flex',[_c('v-progress-circular',{staticClass:"loading-circle center-margin",attrs:{"size":"50","indeterminate":"","color":"orange"}})],1):_c('v-flex',[(!_vm.show_form && !_vm.loading)?_c('div',{staticClass:"d-flex align-center"},[_c('h4',{staticClass:"text-xs-left py-2 mr-2"},[_vm._v("My Account")]),_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Grant other managers, view and/or edit access to your account',
              placement: 'pull-right',
              trigger: 'click hover',
            }),expression:"{\n              content: 'Grant other managers, view and/or edit access to your account',\n              placement: 'pull-right',\n              trigger: 'click hover',\n            }"}],staticClass:"text-xs-right tooltip-icon d-inline-block"},[_vm._v("\n            info\n          ")])],1):_vm._e(),(!_vm.loading && !_vm.show_form)?_c('MultiManagementTable',{attrs:{"show-form":_vm.show_form},on:{"show-form":function($event){_vm.show_form = true},"edit":_vm.editManager}}):_vm._e(),(_vm.show_form && !_vm.loading)?_c('MultiManagementForm',{staticClass:"mt-0",attrs:{"loading":_vm.loading,"edit":_vm.isEdit,"error":_vm.error,"email":_vm.email,"selectedAccess":_vm.selectedAccess},on:{"input:email":function($event){_vm.email = $event},"input:access":function($event){_vm.selectedAccess = $event},"cancel":_vm.resetValue,"confirm":function($event){_vm.selectedAccess === 'revoke' ? _vm.removeProxy() : _vm.setProxy()}}}):_vm._e(),(!_vm.show_form && !_vm.loading)?_c('div',{staticClass:"d-flex align-center my-3"},[_c('h4',{staticClass:"text-xs-left mr-2"},[_vm._v("Other Accounts")]),_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'List of manager who have granted you access to their account',
              placement: 'pull-right',
              trigger: 'click hover',
            }),expression:"{\n              content: 'List of manager who have granted you access to their account',\n              placement: 'pull-right',\n              trigger: 'click hover',\n            }"}],staticClass:"text-xs-right tooltip-icon d-inline-block"},[_vm._v("\n            info\n          ")])],1):_vm._e(),(!_vm.loading && !_vm.show_form)?_c('MultiManagementTable',{attrs:{"show-form":_vm.show_form,"type":"other","empty-data-text":"No Additional Accounts"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }