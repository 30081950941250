<template>
  <div>
    <div
      v-if="(data || []).length === 0"
      class="d-flex align-center justify-center"
    >
      <h4 class="text-xs-center notice my-5">
        {{ emptyDataText }}
      </h4>
    </div>
    <div
      v-else-if="!loading && !showForm"
      class="table-container"
    >
      <table
        class="justify-center align-center text-xs-center mt-2 multimanagement__table"
      >
        <thead>
          <tr>
            <th :class="columnWidth" @click="sortManager" style="cursor: pointer;">Manager</th>
            <th :class="columnWidth">Access</th>
            <th :class="columnWidth" v-if="type === 'self'" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(proxy, i) in data"
            :key="i"
          >
            <td :class="columnWidth" class="longtext">{{ proxy[usernameKey] }}</td>
            <td :class="columnWidth">{{ OwnerProxyHelpers.getRightsText(proxy.rights) }}</td>
            <td :class="columnWidth" v-if="type === 'self'">
              <v-btn 
                small 
                round 
                outline 
                class="plugzio-button create-button mt-1 mr-2" 
                style="min-width: auto; border-radius: 10px;"
                :disabled="!allowedFeatures.EDIT"
                @click="$emit('edit', proxy)"
              >
                EDIT
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!showForm && !loading && type === 'self'" class="d-flex align-center justify-center">
      <div class="notice-description text-xs-center" style="margin-top: 4rem; margin-bottom: 3rem;">
        <v-btn
          small
          class="plugzio-button create-button px-4"
          style="min-width: auto; border-radius: 30px;"
          round
          outline
          :disabled="!allowedFeatures.CREATE"
          @click="$emit('show-form')"
        >
          <v-icon size="18">manage_accounts</v-icon>
          <span class="ml-1">ADD MANAGER</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { SortHelpers } from "@/library/helpers"
import { mapState } from 'vuex'
import { OwnerProxyHelpers } from "@/library/helpers";

export default {
  name: "MultiManagementTable",
  props: {
    type: {
      type: String,
      default: "self" // self or other, self is for proxy given to other accounts, other is for proxy given to self
    },
    showForm: {
      type: Boolean,
      default: false
    },
    emptyDataText: {
      type: String,
      default: "No Managers Added"
    },
  },
  computed: {
    ...mapState({
      proxyGivenAccessToUsers: state => state.OwnerProxy.accessGivenToUsers || [],
      accessReceivedFromUsers: state => state.OwnerProxy.accessReceivedFromUsers || [],
      apiCalls: state => state.Global.apiCalls || [],
    }),
    OwnerProxyHelpers: () => OwnerProxyHelpers,
    data() {
      return this.type === "self" ? this.proxyGivenAccessToUsers : this.accessReceivedFromUsers
    },
    loading() {
      return this.apiCalls.some(({ url }) => url.includes("owner-proxy/proxies") || url.includes("owner-proxy/owners"))
    },
    columnWidth() {
      return this.type === "self" ? "w-1/3" : "w-1/2"
    },
    usernameKey() {
      return this.type === "self" ? "proxy_username" : "owner_username"
    },
    allowedFeatures() {
      return {
        CREATE: OwnerProxyHelpers.isHaveAccessToFeature('SETTING_OWNER_PROXY_CREATE'),
        EDIT: OwnerProxyHelpers.isHaveAccessToFeature('SETTING_OWNER_PROXY_EDIT'),
      }
    }
  },
  methods: { 
    sortManager() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.data.sort(SortHelpers.compareValues([this.usernameKey], this.sortOrder));
    },
  },
  mounted() {
    this.sortManager();
  }
}
</script>

<style scoped lang="scss">
.multimanagement {
  &__table {
    td.longtext {
      word-break: break-word;
    }
    th {
      background-color: #f5f5f5; 
      border-top: 1px solid #ddd; 
      border-bottom: 1px solid #ddd; 
      border-left: 1px solid #ddd; 
      border-right: 1px solid #ddd; 
      padding: 5px;
    }

    td {
      padding: 5px;
      border-top: 1px solid #ddd; 
      border-bottom: 1px solid #ddd; 
      border-left: 1px solid #ddd; 
      border-right: 1px solid #ddd; 
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      width: 2% !important; 
    }
    
    tr:hover {
      background-color: #f0f0f0; 
    }
  }
}
.table-container {
  overflow-x: auto;
}
</style>
