<template>
  <v-dialog v-model="showModal" :max-width="menu == null ? 800 : 600">
    <v-card style="border-radius: 10px">
      <v-card-title>
        <h3>{{ title }}</h3>
      </v-card-title>
      <v-alert class="mb-2 py-2" :value="error" type="error" dismissible>
        {{ error }}
      </v-alert>
      <v-card-text class="pa-2">
        <v-container fluid class="mt-2" :class="menu == 'summary-details' && !hasProfiles ? 'mb-4' : 'mb-2'">
          <v-layout align-center justify-center class="ma-0 overflow-auto" :style="heightStyles">
            <v-flex v-if="menu === null">
              <SelectableCards :items="selectableCardItems" :item-container-classes="['xs12', 'md12', 'lg3']" />
            </v-flex>

            <template v-else-if="!hasProfiles">
              <ZeroWithdrawalProfiles v-if="menu == 'zero-profile'" :data="withdrawalProfiles" />
              <v-flex xs12 v-else-if="menu == 'add-profile' && !loading" class="align-center justify-center">
                <v-layout column align-center justify-center>
                  <template v-if="submittedProfile">
                    <v-flex xs12 class="d-flex align-center justify-center">
                      <div class="text-xs-center mr-5">
                        <v-icon size="64" color="#0d920d" class="mx-2">check_circle</v-icon>
                        <h3 class="success--text text-xs-center text-no-wrap">Profile Added</h3>
                      </div>
                      <span class="mt-2 text-xs-justify"> Your new withdrawal profile has been added. Manage it in the wallet's edit section for future transactions.</span>
                    </v-flex>
                  </template>
                  <template v-else-if="!submittedProfile">
                    <v-flex xs12 class="mb-2 ml-5 w-full align-center justify-center">
                      <v-btn
                        class="plugzio-button"
                        outline
                        @click="goNext()"
                        :style="{
                          width: isXsScreen || isMobileApplication ? '78%' : '84%',
                        }"
                      >
                        <span>Add Profile</span>
                      </v-btn>
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
              <PaypalWithdrawalForm
                v-else-if="['paypal-profile-name', 'paypal-email'].includes(menu)"
                :menu="menu"
                :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
                @inputs:paypal="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
                @submit="goNext()"
              />
              <WireWithdrawalForm
                v-else-if="['wire-profile-name', 'wire-customer-information', 'wire-bank-information'].includes(menu)"
                :menu="menu"
                :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
                @inputs:wire="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
                @submit="goNext()"
              />
              <AchWithdrawalForm
                v-else-if="['ach-profile-name', 'ach-customer-information', 'ach-bank-information'].includes(menu)"
                :menu="menu"
                :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
                @inputs:ach="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
                @submit="goNext()"
              />
              <ChequeWithdrawalForm
                v-else-if="['cheque-profile-name', 'cheque-information', 'cheque-mailing'].includes(menu)"
                :menu="menu"
                :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
                @inputs:cheque="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
                @submit="goNext()"
              />
              <ProfileWithdrawalSummary
                v-else-if="menu == 'summary-details'"
                :menu="menu"
                :loading="loading"
                :is-profile-selection="false"
                :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
                @inputs:summary="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
                :wallet="wallet"
                :selected-payment="inputs"
                @selected-payment="inputs = $event"
              />
            </template>

            <template v-else-if="hasProfiles">
              <v-flex v-if="menu == 'paypal-profile-selection'">
                <v-form ref="paypalForm" @submit="goNext()" v-model="valid">
                  <v-text-field
                    v-model="inputs.amount"
                    @keyup.stop.enter="goNext()"
                    label="Amount"
                    outline
                    autocomplete="false"
                    required
                    :rules="[amountValidator]"
                    class="mb-2"
                    autofocus
                    type="number"
                    min="0"
                    :max="currentBalance"
                  />
                  <v-select
                    v-model="inputs.selectedProfile"
                    class="mt-1"
                    outline
                    :items="profileList"
                    label="Select Profile"
                    item-value="value"
                    item-text="label"
                    :rules="[validators.required]"
                  />
                </v-form>
              </v-flex>
              <v-flex v-else-if="menu == 'wire-profile-selection'">
                <v-form ref="wireForm" @submit="goNext()" v-model="valid">
                  <div class="mb-2">
                    <p class="mb-0">
                      Currency: <strong>{{ wallet.currency }}</strong>
                    </p>
                    <p class="mb-0">
                      Current Balance: <strong>{{ currentBalance }}</strong>
                    </p>
                  </div>
                  <v-text-field
                    v-model="inputs.amount"
                    @keyup.stop.enter="goNext()"
                    label="Amount"
                    outline
                    autocomplete="false"
                    required
                    :rules="[amountValidator]"
                    class="mb-2"
                    autofocus
                    type="number"
                    min="0"
                    :max="currentBalance"
                  />
                  <v-select
                    v-model="inputs.selectedProfile"
                    class="mt-1"
                    outline
                    :items="profileList"
                    label="Select Profile"
                    item-value="value"
                    item-text="label"
                    :rules="[validators.required]"
                  />
                </v-form>
              </v-flex>
              <v-flex v-else-if="menu == 'ach-profile-selection'">
                <v-form ref="achForm" @submit="goNext()" v-model="valid">
                  <div class="mb-2">
                    <p class="mb-0">
                      Currency: <strong>{{ wallet.currency }}</strong>
                    </p>
                    <p class="mb-0">
                      Current Balance: <strong>{{ currentBalance }}</strong>
                    </p>
                  </div>
                  <v-text-field
                    v-model="inputs.amount"
                    @keyup.stop.enter="goNext()"
                    label="Amount"
                    outline
                    autocomplete="false"
                    required
                    :rules="[amountValidator]"
                    class="mb-2"
                    autofocus
                    type="number"
                    min="0"
                    :max="currentBalance"
                  />
                  <v-select
                    v-model="inputs.selectedProfile"
                    class="mt-1"
                    outline
                    :items="profileList"
                    label="Select Profile"
                    item-value="value"
                    item-text="label"
                    :rules="[validators.required]"
                  />
                </v-form>
              </v-flex>
              <v-flex v-else-if="menu == 'cheque-profile-selection'">
                <v-form ref="chequeForm" @submit="goNext()" v-model="valid">
                  <div class="mb-2">
                    <p class="mb-0">
                      Currency: <strong>{{ wallet.currency }}</strong>
                    </p>
                    <p class="mb-0">
                      Current Balance: <strong>{{ currentBalance }}</strong>
                    </p>
                  </div>
                  <v-text-field
                    v-model="inputs.amount"
                    @keyup.stop.enter="goNext()"
                    label="Amount"
                    outline
                    autocomplete="false"
                    required
                    :rules="[amountValidator]"
                    class="mb-2"
                    autofocus
                    type="number"
                    min="0"
                    :max="currentBalance"
                  />
                  <v-text-field v-model="inputs.memo" @keyup.stop.enter="goNext()" label="Memo" outline autocomplete="false" required :rules="[validators.required]" class="mb-2" />
                  <v-select
                    v-model="inputs.selectedProfile"
                    class="mt-1"
                    outline
                    :items="profileList"
                    label="Select Profile"
                    item-value="value"
                    item-text="label"
                    :rules="[validators.required]"
                  />
                </v-form>
              </v-flex>
              <ProfileWithdrawalSummary
                v-else-if="menu == 'summary-details'"
                :menu="menu"
                :loading="loading"
                :is-profile-selection="true"
                :inputs-amount="inputs.amount"
                :inputs-memo="inputs.memo"
                :profiles="profileInputs"
                :wallet="wallet"
                :selected-payment="inputs"
                @selected-payment="inputs = $event"
              />
              <v-flex v-else-if="menu == 'done'">
                <p class="text-xs-justify">
                  Thank you for submitting a withdrawal request. Customer support will contact you via e-mail within 1-2 business days to confirm your transaction details. If you
                  do not hear back from us during this time, please reach out to us using the "Chat With Us" option at the bottom of the navigation bar.<br /><br />
                  Note: All transactions are subjected to mailing and processing fees.
                </p>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <template>
          <v-btn v-if="menu == null" color="gray" flat="flat" @click="showModal = false" :disabled="loading">Cancel</v-btn>
          <v-btn v-else-if="menu !== 'done' && !(menu == 'add-profile' && submittedProfile)" color="gray" flat="flat" @click="goBack()" :disabled="loading">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="menu !== 'done' && menu !== 'add-profile'" @click="goNext()" color="green darken-1" flat="flat" :loading="loading" :disabled="!inputValid || loading">
            {{
              menu == "summary-details" && hasProfiles
                ? "Submit Request"
                : menu == "summary-details" && !hasProfiles
                  ? "Confirm"
                  : menu == "zero-profile"
                    ? "Add Withdrawal Profile"
                    : "Next"
            }}
          </v-btn>
          <v-btn v-else-if="menu == 'done' || (menu == 'add-profile' && submittedProfile)" @click="showModal = false" color="green darken-1" flat="flat" :loading="loading">
            Done
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/library/apis/Api";
import { mapState } from "vuex";
import validators from "@/library/helpers/validators";
import SelectableCards from "@components/SelectableCards/SelectableCards";
import PaypalWithdrawalForm from "../modals/PaypalWithdrawalForm.vue";
import WireWithdrawalForm from "../modals/WireWithdrawalForm.vue";
import AchWithdrawalForm from "../modals/AchWithdrawalForm.vue";
import ChequeWithdrawalForm from "../modals/ChequeWithdrawalForm.vue";
import ZeroWithdrawalProfiles from "../zero/WithdrawalProfiles.vue";
import ProfileWithdrawalSummary from "../modals/ProfileWithdrawalSummary.vue";

const paypalDefaultValue = {
  profileName: null,
  paypalEmail: null,
};
const wireDefaultValue = {
  profileName: null,
  customerInfo: {},
  bankInfo: {},
};
const achDefaultValue = {
  profileName: null,
  customerInfo: {},
  bankInfo: {},
};
const chequeDefaultValue = {
  profileName: null,
  chequeInfo: {},
  mailingInfo: {},
};

const defaultValuesMap = {
  paypal: paypalDefaultValue,
  wire: wireDefaultValue,
  ach: achDefaultValue,
  cheque: chequeDefaultValue,
};

export default {
  props: {
    wallet: Object,
    value: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    SelectableCards,
    PaypalWithdrawalForm,
    WireWithdrawalForm,
    AchWithdrawalForm,
    ChequeWithdrawalForm,
    ZeroWithdrawalProfiles,
    ProfileWithdrawalSummary,
  },
  data() {
    return {
      showModal: this.value,
      menu: null,
      submittedProfile: false,
      selectedProfileIndex: null,
      withdrawalProfile: {
        paypalList: [],
        wireList: [],
        chequeList: [],
        achList: [],
      },
      inputs: {
        selectedProfile: null,
        selectedPaymentMethod: "",
        amount: null,
        memo: null,
      },
      valid: true,
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    validators: () => validators,
    currentBalance() {
      return this.wallet.balance.toFixed(2);
    },
    inputValid() {
      if (this.menu == null) return !!this.inputs.selectedPaymentMethod;

      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const profile = this.withdrawalProfile[this.selectedKeyList]?.[this.selectedProfileIndex];

      if (this.menu == `${this.inputs.selectedPaymentMethod}-profile-selection` && this.inputs.selectedPaymentMethod == "cheque")
        return !!this.inputs?.amount && !!this.inputs?.memo && !!this.inputs?.selectedProfile;
      else if (this.menu == `${this.inputs.selectedPaymentMethod}-profile-selection`) return !!this.inputs?.amount && !!this.inputs?.selectedProfile;

      if (this.menu == `${this.inputs.selectedPaymentMethod}-profile-name`) return !!profile?.profileName;

      if (this.menu === "paypal-email") return pattern.test(profile?.paypalEmail);

      if (this.menu === "cheque-information") return !!profile?.chequeInfo.payToTheOrderOf;
      if (this.menu === "cheque-mailing") {
        return !!profile?.mailingInfo.nameOfReceiver && !!profile?.mailingInfo.phoneNumber && !!profile?.mailingInfo.address && pattern.test(profile?.mailingInfo.email);
      }
      if (["ach-customer-information", "wire-customer-information"].includes(this.menu)) {
        return (
          !!profile?.customerInfo.name &&
          !!profile?.customerInfo.phoneNumber &&
          !!profile?.customerInfo.address &&
          !!profile?.customerInfo.type &&
          pattern.test(profile?.customerInfo.email)
        );
      }
      if (this.menu === "ach-bank-information") {
        return (
          !!profile?.bankInfo.name &&
          !!profile?.bankInfo.address &&
          !!profile?.bankInfo.account &&
          !!profile?.bankInfo.code &&
          !!profile?.bankInfo.abaNumber &&
          !!profile?.bankInfo.routing
        );
      }
      if (this.menu === "wire-bank-information") {
        return !!profile?.bankInfo.name && !!profile?.bankInfo.address && !!profile?.bankInfo.account && !!profile?.bankInfo.code && !!profile?.bankInfo.routing;
      }
      if (this.menu === "summary-details" && !this.hasProfiles) {
        if (this.inputs.selectedPaymentMethod == "paypal") return !!profile?.profileName && !!profile?.paypalEmail;
        else if (this.inputs.selectedPaymentMethod == "wire") {
          return (
            !!profile?.profileName &&
            !!profile?.customerInfo.name &&
            !!profile?.customerInfo.phoneNumber &&
            !!profile?.customerInfo.address &&
            !!profile?.customerInfo.type &&
            !!profile?.customerInfo.email &&
            !!profile?.bankInfo.name &&
            !!profile?.bankInfo.address &&
            !!profile?.bankInfo.account &&
            !!profile?.bankInfo.code &&
            !!profile?.bankInfo.routing
          );
        } else if (this.inputs.selectedPaymentMethod == "ach") {
          return (
            !!profile?.profileName &&
            !!profile?.customerInfo.name &&
            !!profile?.customerInfo.phoneNumber &&
            !!profile?.customerInfo.address &&
            !!profile?.customerInfo.type &&
            !!profile?.customerInfo.email &&
            !!profile?.bankInfo.name &&
            !!profile?.bankInfo.address &&
            !!profile?.bankInfo.account &&
            !!profile?.bankInfo.code &&
            !!profile?.bankInfo.abaNumber &&
            !!profile?.bankInfo.routing
          );
        } else if (this.inputs.selectedPaymentMethod == "cheque") {
          return (
            !!profile?.profileName &&
            !!profile?.chequeInfo.payToTheOrderOf &&
            !!profile?.mailingInfo.nameOfReceiver &&
            !!profile?.mailingInfo.phoneNumber &&
            !!profile?.mailingInfo.address &&
            !!profile?.mailingInfo.email
          );
        }
      } else return true;
    },
    heightStyles() {
      let height = "auto";
      if (this.menu === `${this.inputs.selectedPaymentMethod}-profile-selection` && this.inputs.selectedPaymentMethod !== "cheque") height = "200px";
      if (this.menu === `${this.inputs.selectedPaymentMethod}-profile-selection` && this.inputs.selectedPaymentMethod == "cheque") height = "300px";
      if (["wire-customer-information", "ach-customer-information"].includes(this.menu)) height = "450px";
      if (this.menu === "wire-bank-information") height = "600px";
      if (this.menu === "ach-bank-information") height = "720px";
      if (this.menu === "cheque-information") height = "200px";
      if (this.menu === "cheque-mailing") height = "350px";
      if (this.hasProfiles) {
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "paypal") height = "220px";
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "cheque") height = "400px";
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "ach") height = "550px";
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "wire") height = "520px";
      } else if (!this.hasProfiles) {
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "paypal") height = "150px";
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "cheque") height = "250px";
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "ach") height = "420px";
        if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "wire") height = "400px";
      }
      if (this.menu === "add-profile") height = "150px";
      return { height };
    },
    selectedKeyList() {
      let key = "paypalList";
      if (this.inputs.selectedPaymentMethod === "ach") key = "achList";
      if (this.inputs.selectedPaymentMethod === "cheque") key = "chequeList";
      if (this.inputs.selectedPaymentMethod === "wire") key = "wireList";
      return key;
    },
    isXsScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    isMobileApplication() {
      return this.$root.isMobileApplication;
    },
    hasProfiles() {
      return this.withdrawalProfiles && this.withdrawalProfiles.length > 0;
    },
    withdrawalProfiles() {
      if (!this.wallet || !this.wallet.params || !this.menu) return {};

      const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
      const withdrawalProfile = params.withdrawalProfile || {};

      let key = this.selectedKeyList;
      return withdrawalProfile[key] || [];
    },
    profileList() {
      if (Array.isArray(this.withdrawalProfiles)) {
        return this.withdrawalProfiles.map((profile, index) => ({
          value: `withdrawalProfile-${this.selectedKeyList}-${index}`,
          label: profile.profileName,
        }));
      }
      return [];
    },
    profileInputs() {
      const profiles = this.withdrawalProfiles;
      if (profiles.length > 0 && this.inputs.selectedProfile != null) {
        const selectedProfileValue = this.inputs.selectedProfile;
        const profile = profiles.find((_, index) => `withdrawalProfile-${this.selectedKeyList}-${index}` === selectedProfileValue);

        if (!profile) return;

        if (this.inputs.selectedPaymentMethod === "cheque") {
          return {
            profileName: profile.profileName || null,
            chequeInfo: {
              memo: this.inputs.memo || null,
              payToTheOrderOf: profile.chequeInfo.payToTheOrderOf || null,
            },
            mailingInfo: {
              address: profile.mailingInfo.address || null,
              email: profile.mailingInfo.email || null,
              nameOfReceiver: profile.mailingInfo.nameOfReceiver || null,
              phoneNumber: profile.mailingInfo.phoneNumber || null,
            },
          };
        } else if (this.inputs.selectedPaymentMethod === "wire") {
          return {
            profileName: profile.profileName || null,
            customerInfo: {
              name: profile.customerInfo.name || null,
              email: profile.customerInfo.email || null,
              phoneNumber: profile.customerInfo.phoneNumber || null,
              address: profile.customerInfo.address || null,
              type: profile.customerInfo.type || null,
            },
            bankInfo: {
              name: profile.bankInfo.name || null,
              address: profile.bankInfo.address || null,
              account: profile.bankInfo.account || null,
              code: profile.bankInfo.code || null,
              routing: profile.bankInfo.routing || null,
              transit: profile.bankInfo.transit || null,
              institution: profile.bankInfo.institution || null,
            },
          };
        } else if (this.inputs.selectedPaymentMethod === "ach") {
          return {
            profileName: profile.profileName || null,
            customerInfo: {
              name: profile.customerInfo.name || null,
              email: profile.customerInfo.email || null,
              phoneNumber: profile.customerInfo.phoneNumber || null,
              address: profile.customerInfo.address || null,
              type: profile.customerInfo.type || null,
            },
            bankInfo: {
              name: profile.bankInfo.name || null,
              address: profile.bankInfo.address || null,
              account: profile.bankInfo.account || null,
              code: profile.bankInfo.code || null,
              abaNumber: profile.bankInfo.abaNumber || null,
              routing: profile.bankInfo.routing || null,
              transit: profile.bankInfo.transit || null,
              institution: profile.bankInfo.institution || null,
            },
          };
        } else {
          return {
            profileName: profile.profileName || null,
            paypalEmail: profile.paypalEmail || null,
          };
        }
      }
    },
    selectableCardItems() {
      const items = [
        {
          title: "Paypal",
          class: { selected: this.inputs.selectedPaymentMethod === "paypal" },
          icon: "paypal.png",
          iconType: "image",
          onClick: () => (this.inputs.selectedPaymentMethod = "paypal"),
        },
        {
          title: "Wire",
          class: { selected: this.inputs.selectedPaymentMethod === "wire" },
          icon: "wire-transfer-vector.png",
          iconType: "image",
          onClick: () => (this.inputs.selectedPaymentMethod = "wire"),
        },
        {
          title: "ACH",
          class: { selected: this.inputs.selectedPaymentMethod === "ach" },
          icon: "ach.png",
          iconType: "image",
          onClick: () => (this.inputs.selectedPaymentMethod = "ach"),
        },
        {
          title: "Cheque",
          icon: "cheque-payment.png",
          iconType: "image",
          class: { selected: this.inputs.selectedPaymentMethod === "cheque" },
          onClick: () => (this.inputs.selectedPaymentMethod = "cheque"),
        },
      ];
      if (this.wallet && (!this.wallet.currency || this.wallet.currency !== "USD")) {
        return items.filter((item) => item.title !== "ACH");
      }

      return items;
    },
    title() {
      if (this.menu != null) {
        if (this.inputs.selectedPaymentMethod)
          return `${
            this.inputs.selectedPaymentMethod == "ach"
              ? this.inputs.selectedPaymentMethod.toUpperCase()
              : this.inputs.selectedPaymentMethod.charAt(0).toUpperCase() + this.inputs.selectedPaymentMethod.slice(1).toLowerCase()
          } Withdrawal`;
      }
      return "Request Withdrawal";
    },
    walletParams() {
      if (!this.wallet || !this.wallet.params) return {};
      const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
      return params || {};
    },
  },
  watch: {
    value: function (value) {
      this.showModal = value;
    },
    showModal: function (value) {
      if (!value) this.resetValue();
      this.menu = null;
      this.submittedProfile = false;
      this.$emit("input", value);
    },
    menu: function () {
      this.error = null;
    },
    wallet: {
      handler(newVal) {
        const params = typeof newVal?.params === "string" ? JSON.parse(newVal.params) : newVal?.params || {};
        this.withdrawalProfile = {
          paypalList: params.withdrawalProfile?.paypalList || [],
          wireList: params.withdrawalProfile?.wireList || [],
          chequeList: params.withdrawalProfile?.chequeList || [],
          achList: params.withdrawalProfile?.achList || [],
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    amountValidator(value) {
      if (parseFloat(value) > this.currentBalance) return `Amount must be lower than current balance ( ${this.currentBalance} )`;
      else if (parseFloat(value) <= 0) return "Please provide amount greated than 0.00";
      else return true;
    },

    goBack() {
      switch (this.menu) {
        case null:
          this.showModal = false;
          this.resetValue();
          break;

        case `${this.inputs.selectedPaymentMethod}-profile-selection`:
          this.resetValue();
          this.submittedProfile = false;
          this.menu = null;
          break;

        case "zero-profile":
          this.menu = null;
          this.submittedProfile = false;
          this.resetValue();
          break;

        case "summary-details":
          if (!this.hasProfiles) {
            if (this.inputs.selectedPaymentMethod == "paypal") this.menu = "paypal-email";
            if (this.inputs.selectedPaymentMethod == "wire") this.menu = "wire-bank-information";
            if (this.inputs.selectedPaymentMethod == "ach") this.menu = "ach-bank-information";
            if (this.inputs.selectedPaymentMethod == "cheque") this.menu = "cheque-mailing";
          } else if (this.hasProfiles) {
            this.menu = `${this.inputs.selectedPaymentMethod}-profile-selection`;
          }
          break;

        case "add-profile":
          this.menu = null;
          this.submittedProfile = false;
          this.resetValue();
          break;

        case `${this.inputs.selectedPaymentMethod}-profile-name`:
          this.withdrawalProfile[this.selectedKeyList].pop();
          this.selectedProfileIndex = null;
          this.menu = "add-profile";
          break;

        case "paypal-email":
          this.menu = "paypal-profile-name";
          break;

        case "wire-customer-information":
          this.menu = "wire-profile-name";
          break;
        case "wire-bank-information":
          this.menu = "wire-customer-information";
          break;

        case "ach-customer-information":
          this.menu = "ach-profile-name";
          break;
        case "ach-bank-information":
          this.menu = "ach-customer-information";
          break;

        case "cheque-information":
          this.menu = "cheque-profile-name";
          break;
        case "cheque-mailing":
          this.menu = "cheque-information";
          break;
      }
    },
    goNext() {
      switch (this.menu) {
        case null:
          if (this.inputs.selectedPaymentMethod && !this.hasProfiles) this.menu = "zero-profile";
          if (this.inputs.selectedPaymentMethod && this.hasProfiles) this.menu = `${this.inputs.selectedPaymentMethod}-profile-selection`;
          break;

        case `${this.inputs.selectedPaymentMethod}-profile-selection`:
          this.menu = "summary-details";
          break;

        case "summary-details":
          this.menu = "summary-details";
          if (this.hasProfiles) {
            this.submit();
          } else if (!this.hasProfiles) {
            this.addProfile();
          }
          break;

        case "zero-profile":
          this.menu = "add-profile";
          break;

        case "add-profile":
          const selectedDefaultValue = defaultValuesMap[this.inputs.selectedPaymentMethod];
          this.withdrawalProfile[this.selectedKeyList].push(selectedDefaultValue);
          this.selectedProfileIndex = this.withdrawalProfile[this.selectedKeyList].length - 1;
          this.menu = `${this.inputs.selectedPaymentMethod}-profile-name`;
          break;

        case "paypal-profile-name":
          this.menu = "paypal-email";
          break;
        case "paypal-email":
          this.menu = "summary-details";
          break;

        case "wire-profile-name":
          this.menu = "wire-customer-information";
          break;
        case "wire-customer-information":
          this.menu = "wire-bank-information";
          break;
        case "wire-bank-information":
          this.menu = "summary-details";
          break;

        case "ach-profile-name":
          this.menu = "ach-customer-information";
          break;
        case "ach-customer-information":
          this.menu = "ach-bank-information";
          break;
        case "ach-bank-information":
          this.menu = "summary-details";
          break;

        case "cheque-profile-name":
          this.menu = "cheque-information";
          break;

        case "cheque-information":
          this.menu = "cheque-mailing";
          break;

        case "cheque-mailing":
          this.menu = "summary-details";
          break;
      }
    },
    async addProfile() {
      this.error = null;
      this.loading = true;

      const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
      const payload = {
        walletId: this.wallet.id,
        params: JSON.stringify({
          ...params,
          withdrawalProfile: this.withdrawalProfile,
        }),
      };

      try {
        await Api.walletsSetParams(this.role, payload);
        this.loading = false;
        this.$emit("success");
        this.menu = "add-profile";
        this.submittedProfile = true;
        this.$store.dispatch("snackbar", {
          message: this.successMsg,
        });
      } catch (err) {
        this.loading = false;
        this.error = err.response.data;
        this.$store.dispatch("snackbar", {
          message: "Unable to create the profile. Please try again later.",
        });
      }
    },
    async submit() {
      this.error = null;
      this.loading = true;

      if (!!this.error) return;

      let comments =
        `\n        ${this.role === "admin" ? "Admin" : "Manager"}: ${localStorage.getItem("email")}\n` +
        `        Wallet Name: ${this.wallet.description}\n` +
        `        Currency: ${this.wallet.currency}\n` +
        `        Current Balance: ${this.currentBalance}\n` +
        "---------------------------------------------------\n" +
        `Withdrawal Method: ${this.inputs.selectedPaymentMethod.toUpperCase()}\n` +
        `Profile Name: ${this.profileInputs.profileName}\n`;

      if (this.inputs.selectedPaymentMethod == "paypal") {
        comments += `       Paypal E-mail: ${this.profileInputs.paypalEmail}`;
      } else if (this.inputs.selectedPaymentMethod == "cheque") {
        comments +=
          `\n       Cheque Information:\n` +
          `                   Pay To The Order Of: ${this.profileInputs.chequeInfo.payToTheOrderOf}\n` +
          `                   Memo: ${this.inputs.memo}\n` +
          `                   Amount: ${this.inputs.amount}\n` +
          `                   Currency: ${this.wallet.currency}\n` +
          `\n       Mailing Information:\n` +
          `                   Name of Receiver: ${this.profileInputs.mailingInfo.nameOfReceiver}\n` +
          `                   Phone Number: ${this.profileInputs.mailingInfo.phoneNumber}\n` +
          `                   E-mail Address: ${this.profileInputs.mailingInfo.email}\n` +
          `                   Mailing Address: ${this.profileInputs.mailingInfo.address}\n`;
      } else if (this.inputs.selectedPaymentMethod == "wire") {
        comments +=
          `\n       Customer Information:\n` +
          `                   Name: ${this.profileInputs.customerInfo.name}\n` +
          `                   E-mail: ${this.profileInputs.customerInfo.email}\n` +
          `                   Phone Number: ${this.profileInputs.customerInfo.phoneNumber}\n` +
          `                   Address: ${this.profileInputs.customerInfo.address}\n` +
          `                   Type: ${this.profileInputs.customerInfo.type}\n` +
          `\n       Bank Information:\n` +
          `                   Name: ${this.profileInputs.bankInfo.name}\n` +
          `                   Address: ${this.profileInputs.bankInfo.address}\n` +
          `                   Account Number: ${this.profileInputs.bankInfo.account}\n` +
          `                   Swift Code: ${this.profileInputs.bankInfo.code}\n` +
          `                   Routing Number: ${this.profileInputs.bankInfo.routing}\n` +
          `                   Transit Number: ${this.profileInputs.bankInfo.transit || "N/A"}\n` +
          `                   Institution Number: ${this.profileInputs.bankInfo.institution || "N/A"}`;
      } else if (this.inputs.selectedPaymentMethod == "ach") {
        comments +=
          `\n       Customer Information:\n` +
          `                   Name: ${this.profileInputs.customerInfo.name}\n` +
          `                   E-mail: ${this.profileInputs.customerInfo.email}\n` +
          `                   Phone Number: ${this.profileInputs.customerInfo.phoneNumber}\n` +
          `                   Address: ${this.profileInputs.customerInfo.address}\n` +
          `                   Type: ${this.profileInputs.customerInfo.type}\n` +
          `\n       Bank Information:\n` +
          `                   Name: ${this.profileInputs.bankInfo.name}\n` +
          `                   Address: ${this.profileInputs.bankInfo.address}\n` +
          `                   Account Number: ${this.profileInputs.bankInfo.account}\n` +
          `                   Swift Code: ${this.profileInputs.bankInfo.code}\n` +
          `                   ABA Number: ${this.profileInputs.bankInfo.abaNumber}\n` +
          `                   Routing Number: ${this.profileInputs.bankInfo.routing}\n` +
          `                   Transit Number: ${this.profileInputs.bankInfo.transit || "N/A"}\n` +
          `                   Institution Number: ${this.profileInputs.bankInfo.institution || "N/A"}`;
      }

      const payload = {
        walletId: this.wallet.id,
        amount: parseFloat(this.inputs.amount),
        comments: comments,
      };
      await Api.walletsRequestPayout(this.role, payload)
        .then(() => {
          this.menu = "done";
          this.$emit("success");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetValue() {
      this.selectedProfileIndex = null;
      this.loading = false;
      this.error = null;
      this.inputs = {
        memo: null,
        amount: null,
        selectedProfile: null,
        selectedPaymentMethod: "",
      };
      this.withdrawalProfile = { ...this.withdrawalProfile, ...(this.walletParams ? this.walletParams.withdrawalProfile : {}) };
    },
  },
  async mounted() {
    await this.$store.dispatch("Wallet/fetchWithdrawalProfiles");
  },
};
</script>
