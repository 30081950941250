<template>
  <v-flex>
    <v-card-text>
      <v-form ref="MultiManagementForm">
        <v-text-field
          v-if="!edit"
          v-model="ownerEmail"
          outline
          label="E-mail"
          :rules="emailInputValid"
        />
        <template v-if="edit">
          <div class="edit-access-manager">
            <h4 class="text-xs-left" style="border-bottom: 1px solid #ccc;">Edit Access for Manager</h4>
            <div class="email-wrapper">
              <span>{{ email }}</span>
            </div>
          </div>
        </template>

        <v-select
          v-model="access"
          :items="accessOptions"
          label="Access"
          item-value="value"
          item-text="label"
          :rules="[validators.required]"
          outline
          required
          hide-details
          class="my-3"
          style="align-items: center; justify-content: center;"
        />
        <span class="text-xs-left d-flex mt-1 ml-2">{{ description }}</span>
      </v-form>
    </v-card-text>
    <v-divider class="ma-auto"></v-divider>
    <v-card-actions class="pa-0 flex flex-wrap">
      <v-btn color="gray" flat="flat" :disabled="loading" @click="$emit('cancel')">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="green" flat="flat" :disabled="loading || !isEmailValid || !selectedAccess" @click="$emit('confirm')">
        {{ error ? 'Retry' : 'Confirm' }}
      </v-btn>
    </v-card-actions>
  </v-flex>
</template>

<script>
import validators from "@/library/helpers/validators"; 
import { OwnerProxyPermissions } from "@/constants"
import { mapState } from "vuex";

export default {
  name: "MultiManagementForm",
  props: {
    edit: {
      type: Boolean, 
      default: false
    },
    email: { 
      type: String | Array, 
      required: true,
    },
    selectedAccess: { 
      type: String,
      default: "view"
    },
    error: { 
      type: Boolean, 
      default: false 
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      proxyGivenAccessToUsers: state => state.OwnerProxy.accessGivenToUsers,
    }),
    validators: () => validators,
    emailInputValid() {
      let basicRules = [
        this.validators.required,
        this.validators.email,
      ]
      if (Array.isArray(this.proxyGivenAccessToUsers)) {
        const emailExists = this.proxyGivenAccessToUsers.some(proxy => proxy.proxy_username === this.email);
        if (emailExists) {
          basicRules.push(() => 'This email already has permission access. Go back and choose "Edit" to modify the access.');
        }
      }
      return basicRules;
    },
    ownerEmail: {
      get() { return this.email },
      set(v) { return this.$emit("input:email", v.trim()) }
    },
    access: {
      get() { return this.selectedAccess },
      set(v) { return this.$emit("input:access", v) }
    },
    accessOptions() {
      const options = [
        { value: OwnerProxyPermissions.VIEW, label: "View Access" },
        { value: OwnerProxyPermissions.FULL, label: "Full Access" },
      ]
      if (this.edit) options.push({ value: "revoke", label: "Revoke Access" })
      return options
    },
    description() {
      if (this.access === OwnerProxyPermissions.VIEW) return "Manager has 'View Only' permissions and cannot make edits to your account.";
      if (this.access === OwnerProxyPermissions.FULL) return "Manager has full read and edit permission to your account.";
      if (this.access === 'revoke') return "Manager has no access to your account.";
      else return "";
    },
    isEmailValid() {
      if (this.edit) return true; 
      else return this.emailInputValid.every(rule => rule(this.email) === true);
    },
  }
}
</script>

<style>
.edit-access-manager {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.email-wrapper {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}
</style>