<template>
  <v-layout
    v-if="visible"
    :column="layout === 'column'"
    :row="layout === 'row'"
    class="policy"
  >
    <v-layout
      class="policy-links"
      :mb-2="layout === 'column'"
    >
      <v-flex xs-4>
        <span class="link" @click="openDialog('terms-of-service')">Term of Service</span>
      </v-flex>
      <v-flex xs-4>
        <span class="link" @click="openDialog('privacy-policy')">Privacy Policy</span>
      </v-flex>
      <v-flex xs-4>
        <span class="link" @click="openDialog('return-policy')">Return Policy</span>
      </v-flex>
    </v-layout>
    <v-layout
      class="policy-copyright"
      column
    >
      <span>Copyright Plugzio Power Private Limited , All rights reserved</span>
      <span>Pkt .U&V, Number 132-D, Shalimar Bagh, New Delhi 110088 , India</span>
      <span>Contact us at +91 8360531520</span>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { TextHelpers } from "@/library/helpers"
export default {
  name: "Policy",
  props: {
    layout: {
      type: String,
      default: "column"
    }
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapGetters("Global", {
      countryCode: "countryCode"
    }),
    dialogTitle() {
      if (this.selected === 'terms-of-service') {
        return 'Term of Service'
      } else if (this.selected === 'privacy-policy') {
        return 'Privacy Policy'
      } else if (this.selected === 'return-policy') {
        return 'Return Policy'
      }
    },
    visible() {
      return this.countryCode === "IN" // only show copyright if country is IN
    }
  },
  methods: {
    openDialog(selected) {
      this.selected = selected
      this.$store.commit("Global/setState", {
        globalDialog: {
          show: true,
          title: this.dialogTitle,
          content: `<div class="document">${ TextHelpers.getPolicyText(selected, this.countryCode) }</div>`,
          onClosed: () => this.selected = null,
          showCancel: false
        }
      })
    }
  },
}
</script>