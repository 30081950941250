<template>
  <v-dialog v-model="showProfileModal" :max-width="menu == null ? 800 : 600" persistent no-click-animation>
    <v-card style="border-radius: 10px">
      <v-card-title class="d-flex justify-space-between">
        <h3>{{ title }}</h3>
      </v-card-title>
      <v-alert :value="error || success" :type="error ? 'error' : 'success'" dismissible @input="isDismissed">
        {{ error ? errorMsg : successMsg }}
      </v-alert>
      <v-container fluid :style="containerStyles">
        <v-layout v-if="menu !== 'profile-list'" align-center justify-center class="ma-2 overflow-auto" :style="heightStyles">
          <v-flex v-if="menu == null">
            <SelectableCards :items="selectableCardItems" :item-container-classes="['xs12', 'md12', 'lg3']" />
          </v-flex>
          <PaypalWithdrawalForm
            v-else-if="['paypal-profile-name', 'paypal-email'].includes(menu)"
            :menu="menu"
            :is-edit="isEditProfile"
            :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
            @inputs:paypal="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
            @submit="goNext()"
          />
          <WireWithdrawalForm
            v-else-if="['wire-profile-name', 'wire-customer-information', 'wire-bank-information'].includes(menu)"
            :menu="menu"
            :is-edit="isEditProfile"
            :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
            @inputs:wire="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
            @submit="goNext()"
          />
          <AchWithdrawalForm
            v-else-if="['ach-profile-name', 'ach-customer-information', 'ach-bank-information'].includes(menu)"
            :menu="menu"
            :is-edit="isEditProfile"
            :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
            @inputs:ach="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
            @submit="goNext()"
          />
          <ChequeWithdrawalForm
            v-else-if="['cheque-profile-name', 'cheque-information', 'cheque-mailing'].includes(menu)"
            :menu="menu"
            :is-edit="isEditProfile"
            :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
            @inputs:cheque="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
            @submit="goNext()"
          />
          <ProfileWithdrawalSummary
            v-else-if="menu == 'summary-details'"
            :menu="menu"
            :loading="loading"
            :inputs="withdrawalProfile[selectedKeyList][selectedProfileIndex]"
            @inputs:summary="withdrawalProfile[selectedKeyList][selectedProfileIndex] = $event"
            :wallet="wallet"
            :selected-payment="inputs"
            @selected-payment="inputs = $event"
          />
          <v-flex v-else-if="menu == 'delete-profile' && !loading" xs12 class="d-flex flex-column align-center justify-center text-center">
            <h3 class="red--text mb-4">WARNING</h3>
            <span>
              <strong>
                This action cannot be undone. <br />
                Please confirm that you would like to delete {{ profileToDelete }}
              </strong>
            </span>
          </v-flex>
          <v-flex v-else-if="loading && isDeleteProfile" xs12 class="align-center d-flex justify-center">
            <v-progress-circular class="loading-circle d-flex mb-3" size="25" indeterminate color="orange" />
          </v-flex>
        </v-layout>
        <v-layout v-else-if="menu == 'profile-list' && !loading" row wrap align-center justify-center class="ma-2 overflow-auto" :style="heightStyles">
          <v-flex xs12 class="align-center justify-center">
            <v-layout column align-center justify-center>
              <template v-if="hasProfiles">
                <v-flex xs12 v-for="(profile, index) in withdrawalProfiles" :key="index" class="mb-2 ml-5 w-full align-center justify-center d-inline-block">
                  <v-btn
                    class="plugzio-button"
                    :class="isXsScreen || isMobileApplication ? 'py-4' : 'py-3'"
                    outline
                    @click="handleEdit(index)"
                    :style="{
                      width: isXsScreen || isMobileApplication ? '74%' : '84%',
                    }"
                  >
                    <span style="word-break: break-word; white-space: break-spaces">Configure {{ profile.profileName }}</span>
                  </v-btn>
                  <v-btn flat icon @click="handleDeleteProfile(profile.profileName, index)" class="mb-2 ml-1">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-flex>
              </template>
              <v-flex
                xs12
                class="mb-2 ml-5 w-full align-center justify-center"
                :class="{
                  'ml-5': withdrawalProfiles.length === 0,
                  'ml-3': withdrawalProfiles.length > 0,
                }"
              >
                <v-btn
                  v-if="!hasProfiles || withdrawalProfiles.length < 3"
                  class="plugzio-button"
                  outline
                  @click="goNext()"
                  :style="{
                    width: isXsScreen || isMobileApplication ? '74%' : '84%',
                  }"
                >
                  <span>Add Profile</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-btn color="gray" flat="flat" @click="goBack()" :disabled="loading">
          {{ menu == null ? "Cancel" : "Back" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="menu !== 'profile-list'" @click="goNext()" color="green darken-1" flat="flat" :loading="loading" :disabled="!inputValid || loading">
          {{ menu == "delete-profile" ? "Confirm" : menu == "summary-details" ? "Add Profile" : "Next" }}
        </v-btn>
        <v-btn v-else-if="menu == 'profile-list'" @click="closeDialog" color="green darken-1" flat="flat" :loading="loading" :disabled="loading"> Done </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/library/apis/Api";
import { mapState } from "vuex";
import validators from "@/library/helpers/validators";
import SelectableCards from "@components/SelectableCards/SelectableCards";
import PaypalWithdrawalForm from "@/components/modals/PaypalWithdrawalForm.vue";
import WireWithdrawalForm from "@/components/modals/WireWithdrawalForm.vue";
import AchWithdrawalForm from "@/components/modals/AchWithdrawalForm.vue";
import ChequeWithdrawalForm from "@/components/modals/ChequeWithdrawalForm.vue";
import ProfileWithdrawalSummary from "@/components/modals/ProfileWithdrawalSummary.vue";

const defaultPaypalWithdrawalProfile = {
  profileName: null,
  paypalEmail: null,
};
const defaultWireWithdrawalProfile = {
  profileName: null,
  customerInfo: {},
  bankInfo: {},
};
const defaultAchWithdrawalProfile = {
  profileName: null,
  customerInfo: {},
  bankInfo: {},
};
const defaultChequeWithdrawalProfile = {
  profileName: null,
  chequeInfo: {},
  mailingInfo: {},
};

export default {
  props: {
    wallet: Object,
    show: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    SelectableCards,
    PaypalWithdrawalForm,
    WireWithdrawalForm,
    AchWithdrawalForm,
    ChequeWithdrawalForm,
    ProfileWithdrawalSummary,
  },
  data() {
    return {
      profileToDeleteIndex: null,
      profileToDelete: null,
      updatedProfileData: null,
      isEditProfile: false,
      isDeleteProfile: false,
      menu: null,
      selectedProfileIndex: null,
      withdrawalProfile: {
        paypalList: [],
        wireList: [],
        chequeList: [],
        achList: [],
      },
      inputs: {
        selectedPaymentMethod: null,
      },
      loading: false,
      error: false,
      success: false,
      errorMsg: null,
      successMsg: null,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    validators: () => validators,
    isXsScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    isMobileApplication() {
      return this.$root.isMobileApplication;
    },
    inputValid() {
      if (this.menu === null) return !!this.inputs.selectedPaymentMethod;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const profile = this.withdrawalProfile[this.selectedKeyList]?.[this.selectedProfileIndex];

      if (this.menu === `${this.inputs.selectedPaymentMethod}-profile-name`) return !!profile?.profileName;

      if (this.menu === "paypal-email") return pattern.test(profile?.paypalEmail);

      if (this.menu === "cheque-information") return !!profile?.chequeInfo.payToTheOrderOf;
      if (this.menu === "cheque-mailing") {
        return !!profile?.mailingInfo.nameOfReceiver && !!profile?.mailingInfo.phoneNumber && !!profile?.mailingInfo.address && pattern.test(profile?.mailingInfo.email);
      }

      if (["ach-customer-information", "wire-customer-information"].includes(this.menu)) {
        return (
          !!profile?.customerInfo.name &&
          !!profile?.customerInfo.phoneNumber &&
          !!profile?.customerInfo.address &&
          !!profile?.customerInfo.type &&
          pattern.test(profile?.customerInfo.email)
        );
      }
      if (this.menu === "ach-bank-information") {
        return (
          !!profile?.bankInfo.name &&
          !!profile?.bankInfo.address &&
          !!profile?.bankInfo.account &&
          !!profile?.bankInfo.code &&
          !!profile?.bankInfo.abaNumber &&
          !!profile?.bankInfo.routing
        );
      }
      if (this.menu === "wire-bank-information") {
        return !!profile?.bankInfo.name && !!profile?.bankInfo.address && !!profile?.bankInfo.account && !!profile?.bankInfo.code && !!profile?.bankInfo.routing;
      }
      if (this.menu === "summary-details") {
        if (this.inputs.selectedPaymentMethod == "paypal") return !!profile?.profileName && !!profile?.paypalEmail;
        else if (this.inputs.selectedPaymentMethod == "wire") {
          return (
            !!profile?.profileName &&
            !!profile?.customerInfo.name &&
            !!profile?.customerInfo.phoneNumber &&
            !!profile?.customerInfo.address &&
            !!profile?.customerInfo.type &&
            !!profile?.customerInfo.email &&
            !!profile?.bankInfo.name &&
            !!profile?.bankInfo.address &&
            !!profile?.bankInfo.account &&
            !!profile?.bankInfo.code &&
            !!profile?.bankInfo.routing
          );
        } else if (this.inputs.selectedPaymentMethod == "ach") {
          return (
            !!profile?.profileName &&
            !!profile?.customerInfo.name &&
            !!profile?.customerInfo.phoneNumber &&
            !!profile?.customerInfo.address &&
            !!profile?.customerInfo.type &&
            !!profile?.customerInfo.email &&
            !!profile?.bankInfo.name &&
            !!profile?.bankInfo.address &&
            !!profile?.bankInfo.account &&
            !!profile?.bankInfo.code &&
            !!profile?.bankInfo.abaNumber &&
            !!profile?.bankInfo.routing
          );
        } else if (this.inputs.selectedPaymentMethod == "cheque") {
          return (
            !!profile?.profileName &&
            !!profile?.chequeInfo.payToTheOrderOf &&
            !!profile?.mailingInfo.nameOfReceiver &&
            !!profile?.mailingInfo.phoneNumber &&
            !!profile?.mailingInfo.address &&
            !!profile?.mailingInfo.email
          );
        }
      } else return true;
    },
    hasProfiles() {
      return this.withdrawalProfiles && this.withdrawalProfiles.length > 0;
    },
    showProfileModal: {
      get() {
        return this.show;
      },
      set(v) {
        return this.$emit("input:show", v);
      },
    },
    containerStyles() {
      let styles = {};

      if (["wire-customer-information", "ach-customer-information"].includes(this.menu)) {
        styles.marginTop = "5px";
      } else if (["wire-bank-information", "ach-bank-information"].includes(this.menu)) {
        styles.marginTop = "15px";
      } else if (this.menu === "summary-details") {
        styles = {
          paddingTop: "0px",
          marginTop: "0px",
          marginBottom: "24px",
        };
      } else {
        styles = {
          marginTop: "auto",
          marginBottom: "auto",
        };
      }

      return styles;
    },
    heightStyles() {
      let height = "auto";
      if (["wire-customer-information", "ach-customer-information"].includes(this.menu)) height = "450px";
      if (this.menu === "wire-bank-information") height = "600px";
      if (this.menu === "ach-bank-information") height = "720px";
      if (this.menu === "cheque-information") height = "200px";
      if (this.menu === "cheque-mailing") height = "350px";
      if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "paypal") height = "200px";
      if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "cheque") height = "300px";
      if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "ach") height = "500px";
      if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "wire") height = "450px";
      if (this.menu === "profile-list" && this.withdrawalProfiles.length === 0) height = "150px";
      if (this.menu === "profile-list" && this.withdrawalProfiles.length > 0) height = "300px";
      return { height };
    },
    selectableCardItems() {
      const items = [
        {
          title: "Paypal",
          class: { selected: this.inputs.selectedPaymentMethod === "paypal" },
          icon: "paypal.png",
          iconType: "image",
          onClick: () => (this.inputs.selectedPaymentMethod = "paypal"),
        },
        {
          title: "Wire",
          class: { selected: this.inputs.selectedPaymentMethod === "wire" },
          icon: "wire-transfer-vector.png",
          iconType: "image",
          onClick: () => (this.inputs.selectedPaymentMethod = "wire"),
        },
        {
          title: "ACH",
          class: { selected: this.inputs.selectedPaymentMethod === "ach" },
          icon: "ach.png",
          iconType: "image",
          onClick: () => (this.inputs.selectedPaymentMethod = "ach"),
        },
        {
          title: "Cheque",
          icon: "cheque-payment.png",
          iconType: "image",
          class: { selected: this.inputs.selectedPaymentMethod === "cheque" },
          onClick: () => (this.inputs.selectedPaymentMethod = "cheque"),
        },
      ];
      if (this.wallet && (!this.wallet.currency || this.wallet.currency !== "USD")) {
        return items.filter((item) => item.title !== "ACH");
      }

      return items;
    },
    title() {
      if (this.menu !== null) {
        return `Configure Withdrawal Profile - ${
          this.inputs.selectedPaymentMethod == "ach"
            ? this.inputs.selectedPaymentMethod.toUpperCase()
            : this.inputs.selectedPaymentMethod.charAt(0).toUpperCase() + this.inputs.selectedPaymentMethod.slice(1).toLowerCase()
        }`;
      }
      return "Configure Withdrawal Profiles";
    },
    selectedKeyList() {
      let key = null;
      if (this.inputs.selectedPaymentMethod === "paypal") key = "paypalList";
      if (this.inputs.selectedPaymentMethod === "ach") key = "achList";
      if (this.inputs.selectedPaymentMethod === "cheque") key = "chequeList";
      if (this.inputs.selectedPaymentMethod === "wire") key = "wireList";
      return key;
    },
    withdrawalProfiles() {
      if (!this.wallet || !this.wallet.params || !this.menu) return {};

      const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
      const withdrawalProfile = params.withdrawalProfile || {};

      let key = "paypalList";
      if (this.inputs.selectedPaymentMethod === "ach") key = "achList";
      if (this.inputs.selectedPaymentMethod === "cheque") key = "chequeList";
      if (this.inputs.selectedPaymentMethod === "wire") key = "wireList";

      return withdrawalProfile[key] || [];
    },
    walletParams() {
      if (!this.wallet || !this.wallet.params) return {};
      const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
      return params || {};
    },
  },
  watch: {
    menu: function () {
      this.error = false;
    },
    "inputs.selectedPaymentMethod": function (newVal, oldVal) {
      if (newVal && oldVal !== newVal) {
        this.inputs.selectedPaymentMethod = newVal;
      }
    },
    wallet: {
      handler(newVal) {
        const params = typeof newVal.params === "string" ? JSON.parse(newVal.params) : newVal.params;
        this.withdrawalProfile = {
          paypalList: params.withdrawalProfile?.paypalList || [],
          wireList: params.withdrawalProfile?.wireList || [],
          chequeList: params.withdrawalProfile?.chequeList || [],
          achList: params.withdrawalProfile?.achList || [],
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isDismissed() {
      this.error = false;
      this.success = false;
      this.errorMsg = null;
      this.successMsg = null;
    },
    closeDialog() {
      this.menu = null;
      this.error = false;
      this.errorMsg = null;
      this.success = false;
      this.successMsg = null;
      this.inputs.selectedPaymentMethod = null;
      this.$emit("input:show", false);
      this.reset();
    },
    goBack() {
      if (this.menu == null) {
        this.closeDialog();
      } else if (this.menu == "profile-list") {
        this.error = false;
        this.errorMsg = null;
        this.success = false;
        this.successMsg = null;
        this.menu = null;
      } else if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "paypal") {
        this.menu = "paypal-email";
      } else if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "wire") {
        this.menu = "wire-bank-information";
      } else if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "ach") {
        this.menu = "ach-bank-information";
      } else if (this.menu === "summary-details" && this.inputs.selectedPaymentMethod === "cheque") {
        this.menu = "cheque-mailing";
      } else if (this.menu === "delete-profile") {
        this.reset();
        this.menu = "profile-list";
      } else {
        switch (this.menu) {
          case "paypal-profile-name":
            if (!this.isEditProfile) this.withdrawalProfile.paypalList.pop();
            this.reset();
            this.menu = "profile-list";
            break;
          case "paypal-email":
            this.menu = "paypal-profile-name";
            break;
          case "wire-profile-name":
            if (!this.isEditProfile) this.withdrawalProfile.wireList.pop();
            this.reset();
            this.menu = "profile-list";
            break;
          case "wire-customer-information":
            this.menu = "wire-profile-name";
            break;
          case "wire-bank-information":
            this.menu = "wire-customer-information";
            break;
          case "ach-profile-name":
            if (!this.isEditProfile) this.withdrawalProfile.achList.pop();
            this.reset();
            this.menu = "profile-list";
            break;
          case "ach-customer-information":
            this.menu = "ach-profile-name";
            break;
          case "ach-bank-information":
            this.menu = "ach-customer-information";
            break;
          case "cheque-profile-name":
            if (!this.isEditProfile) this.withdrawalProfile.chequeList.pop();
            this.reset();
            this.menu = "profile-list";
            break;
          case "cheque-information":
            this.menu = "cheque-profile-name";
            break;
          case "cheque-mailing":
            this.menu = "cheque-information";
            break;
        }
      }
    },
    handleDeleteProfile(profileName, index) {
      this.isDeleteProfile = true;
      this.profileToDelete = profileName;
      this.profileToDeleteIndex = index;
      this.goNext();
    },
    handleEdit(index) {
      this.isEditProfile = true;
      this.selectedProfileIndex = index;
      if (this.selectedProfileIndex !== -1) {
        this.goNext();
      }
    },
    goNext() {
      switch (this.menu) {
        case null:
          if (this.inputs.selectedPaymentMethod) {
            this.menu = "profile-list";
          }
          break;
        case "profile-list":
          this.isDismissed();
          if (!this.withdrawalProfile[this.selectedKeyList]) {
            this.withdrawalProfile[this.selectedKeyList] = [];
          }
          if (!Array.isArray(this.withdrawalProfile[this.selectedKeyList])) {
            this.withdrawalProfile[this.selectedKeyList] = [];
          }
          if (this.isDeleteProfile) {
            this.menu = "delete-profile";
          } else if (this.isEditProfile) {
            const existingProfileData = Array.isArray(this.withdrawalProfile[this.selectedKeyList]) ? this.withdrawalProfile[this.selectedKeyList] : [];

            if (this.selectedProfileIndex >= 0 && this.selectedProfileIndex < existingProfileData.length) {
              this.updatedProfileData = { ...existingProfileData[this.selectedProfileIndex] };
            }
            this.menu = `${this.inputs.selectedPaymentMethod}-profile-name`;
          } else if (this.inputs.selectedPaymentMethod === "paypal") {
            this.withdrawalProfile.paypalList.push(defaultPaypalWithdrawalProfile);
            this.selectedProfileIndex = this.withdrawalProfile.paypalList.length - 1;
            this.menu = "paypal-profile-name";
          } else if (this.inputs.selectedPaymentMethod === "wire") {
            this.withdrawalProfile.wireList.push(defaultWireWithdrawalProfile);
            this.selectedProfileIndex = this.withdrawalProfile.wireList.length - 1;
            this.menu = "wire-profile-name";
          } else if (this.inputs.selectedPaymentMethod === "ach") {
            this.withdrawalProfile.achList.push(defaultAchWithdrawalProfile);
            this.selectedProfileIndex = this.withdrawalProfile.achList.length - 1;
            this.menu = "ach-profile-name";
          } else if (this.inputs.selectedPaymentMethod === "cheque") {
            this.withdrawalProfile.chequeList.push(defaultChequeWithdrawalProfile);
            this.selectedProfileIndex = this.withdrawalProfile.chequeList.length - 1;
            this.menu = "cheque-profile-name";
          }
          break;
        case "paypal-profile-name":
          this.menu = "paypal-email";
          break;
        case "paypal-email":
          this.menu = "summary-details";
          break;
        case "wire-profile-name":
          this.menu = "wire-customer-information";
          break;
        case "wire-customer-information":
          this.menu = "wire-bank-information";
          break;
        case "wire-bank-information":
          this.menu = "summary-details";
          break;
        case "ach-profile-name":
          this.menu = "ach-customer-information";
          break;
        case "ach-customer-information":
          this.menu = "ach-bank-information";
          break;
        case "ach-bank-information":
          this.menu = "summary-details";
          break;
        case "cheque-profile-name":
          this.menu = "cheque-information";
          break;
        case "cheque-information":
          this.menu = "cheque-mailing";
          break;
        case "cheque-mailing":
          this.menu = "summary-details";
          break;
        case "summary-details":
          this.submit();
          break;

        case "delete-profile":
          this.removeProfile();
          break;
      }
    },
    async submit() {
      this.error = false;
      this.loading = true;

      const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
      const payload = {
        walletId: this.wallet.id,
        params: JSON.stringify({
          ...params,
          withdrawalProfile: this.withdrawalProfile,
        }),
      };

      try {
        await Api.walletsSetParams(this.role, payload);
        this.loading = false;
        this.$emit("success");
        this.success = true;
        this.successMsg = this.isEditProfile ? "The profile has been successfully updated." : "The profile has been successfully created.";
        this.menu = "profile-list";
        this.reset();
        this.$store.dispatch("snackbar", {
          message: this.successMsg,
        });
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = err.response ? err.message : "Unable to save the profile due to a network or server error.";
        this.$store.dispatch("snackbar", {
          message: this.isEditProfile ? "Unable to update the profile. Please try again later." : "Unable to create the profile. Please try again later.",
        });
      }
    },
    async removeProfile() {
      if (!this.isDeleteProfile) return;

      this.loading = true;
      this.error = false;

      try {
        const params = typeof this.wallet.params === "string" ? JSON.parse(this.wallet.params) : this.wallet.params;
        const withdrawalProfile = params.withdrawalProfile || {};
        let profileList = withdrawalProfile[this.selectedKeyList] || [];

        if (!Array.isArray(profileList)) {
          profileList = [];
        }

        if (this.profileToDeleteIndex < 0 || this.profileToDeleteIndex >= profileList.length) {
          throw new Error(`Profile "${this.profileToDelete}" was not found.`);
        }
        profileList.splice(this.profileToDeleteIndex, 1);

        const payload = {
          walletId: this.wallet.id,
          params: JSON.stringify({
            ...params,
            withdrawalProfile,
          }),
        };

        await Api.walletsSetParams(this.role, payload);

        this.$store.dispatch("snackbar", {
          message: "The profile has been successfully deleted.",
        });

        this.$emit("success");
        this.success = true;
        this.successMsg = `The profile "${this.profileToDelete}" has been successfully deleted.`;
        this.menu = "profile-list";
        this.reset();
      } catch (error) {
        console.error("Error deleting profile:", error);
        this.$store.dispatch("snackbar", {
          message: error.message || `Unable to delete the profile "${this.profileToDelete}". Please try again later.`,
        });
        this.error = true;
        this.errorMsg = error.message || `Unable to delete the profile "${this.profileToDelete}". Please try again later.`;
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.profileToDelete = null;
      this.profileToDeleteIndex = null;
      this.isEditProfile = false;
      this.isDeleteProfile = false;
      this.selectedProfileIndex = null;
      this.updatedProfileData = null;
      this.loading = false;
      this.error = null;
      this.withdrawalProfile = { ...this.withdrawalProfile, ...(this.walletParams ? this.walletParams.withdrawalProfile : {}) };
    },
  },
  async mounted() {
    await this.$store.dispatch("Wallet/fetchWithdrawalProfiles");
  },
};
</script>
