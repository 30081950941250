<template>
  <span class="payment-plan__text">
    <span v-html="text"/>
    <span class="mx-1 red--text" v-if="restrictSame && isValueSame">( the value should not be the same )</span>
    <span
      v-if="useChargeText"
      v-html="chargeText"
    />
  </span>
</template>

<script>
import { getPaymentPlanText, isPaymentPlanValueSame } from "@/library/helpers"

export default {
  name: "PaymentPlanText",
  props: {
    type: String,
    value: {
      required: true
    },
    useChargeText: { type: Boolean, default: false },
    restrictSame: { type: Boolean, default: false },
    deviceDisabled: { type: Boolean, default: false },
    isUser: { type: Boolean, default: false },
  },
  computed: {
    chargeText() {
      return !this.isUser
        ? `, I would like to ${this.deviceDisabled ? '<strong>disable</strong> the device.' : 'charge users...'}`
        : `, ${this.deviceDisabled ? 'the device will be <strong>disable</strong>.' : 'you will be billed...'}`
    },
    text() { return getPaymentPlanText({ type: this.type, values: this.value }) },
    isValueSame() { return isPaymentPlanValueSame({ type: this.type, values: this.value }) }
  },
}
</script>